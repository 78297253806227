<template>
  <div class="error-page">
    <h1>Oops! There is no content for this request</h1>
  </div>
</template>

<style lang="scss">
.error-page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: calc(100vh);
  justify-content: center;
  justify-items: center;
}
</style>
